import TablePagination from '@mui/material/TablePagination'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { useGridApiContext, useGridSelector, gridPaginationModelSelector } from '@mui/x-data-grid-premium'
import { type ChangeEvent } from 'react'

interface CustomPaginationProps {
  setPageChanged?: (pageChanged: boolean) => void
}

const CustomPagination = (props: CustomPaginationProps) => {
  const { setPageChanged = () => {} } = props
  const apiRef = useGridApiContext()
  const paginationModel = useGridSelector(apiRef, gridPaginationModelSelector)
  const totalRows = apiRef.current.getRowsCount()

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    apiRef.current.setPage(value)
    setPageChanged(true)
  }

  const handleRowsPerPageChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    apiRef.current.setPageSize(Number(event.target.value))
    setPageChanged(true)
  }

  return (
    <Table>
      <TableFooter>
        <TableRow>
          <TableCell>
            <Typography variant='body2' sx={{ mr: 1, font: 'unset' }}>
              Page: {paginationModel.page + 1} of {Math.ceil(totalRows / paginationModel.pageSize)}
            </Typography>
          </TableCell>
          <TablePagination
            color='primary'
            count={totalRows}
            page={paginationModel.page}
            showFirstButton
            showLastButton
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            onPageChange={handlePageChange}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default CustomPagination
