/* eslint-disable quote-props */
import { useState, useEffect, memo } from 'react'
import useSWR from 'swr'
import { type UserFromToken, type SupporticleInterface } from '../../../../src/dictionaries/commonInterfaces'
import { apiVercelFallback } from '../../../../src/helpers/clientSide/fetch'
import { useTheme } from '@mui/material/styles'

import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import Supporticle from '../../../Support/Supporticle'
import CaseOppMenu from '../../../Support/CaseOppMenu'
import SkeletonText from '../../../tools/SkeletonText'

import css from '../../../tools/tools.module.css'

interface Props {
  campaignId?: string
  user: UserFromToken
  token?: string
  isClientPortal?: boolean
  isLoading?: boolean
  displayId?: string
}

const SupportPanel: React.FC<Props> = ({ user, campaignId, token, isClientPortal, isLoading, displayId }) => {
  const [panelArticles, setPanelArticles] = useState<SupporticleInterface[]>([])
  const [clientPreviewOpen, setClientPreviewOpen] = useState<boolean>(isClientPortal ?? false)

  const theme = useTheme()

  const {
    // Articles by campaign ID
    data: supporticlesData,
    isLoading: supporticlesDataLoading
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  } = useSWR<SupporticleInterface[]>(
    campaignId
      ? !clientPreviewOpen
        ? `/rest/supporticles?campaignId=${campaignId}`
        : `/rest/supporticles?campaignId=${campaignId}&isClientPortal=true&displayId=${displayId}`
      : null,
    async (url) => await apiVercelFallback(url, { headers: { authorization: `Bearer ${token}` } }),
    {
      onError: (error) => {
        console.error('Get Supporticles Error: ', error)
      }
    }
  )

  useEffect(() => {
    if (supporticlesData?.length) {
      setPanelArticles(supporticlesData)
    }
  }, [supporticlesData, isClientPortal])

  const handleScrollToArticle = (event: any) => {
    const article = panelArticles.find((article) => article.articleTitle === event.articleTitle)
    if (article) {
      const articleElement = document.getElementById(article.articleTitle)
      if (articleElement) {
        setTimeout(() => {
          // Allow time to render
          articleElement.scrollIntoView({ behavior: 'smooth' })
        }, 100)
      }
    }
  }

  return (
    <Stack p={2} className={css.blueStyledScrollbar} style={{ overflowY: 'scroll', height: '100%' }}>
      {!isLoading && !supporticlesDataLoading ? (
        <>
          {!clientPreviewOpen ? (
            <>
              <Stack style={{ width: '100%' }} alignItems='center'>
                {user.permissions.includes('create:supporticle') && !isClientPortal && (
                  <Button
                    variant='contained'
                    color='primary'
                    style={{ height: '40px' }}
                    onClick={() => setClientPreviewOpen(true)}
                  >
                    Client Portal Preview
                  </Button>
                )}

                {panelArticles?.length > 1 && (
                  <CaseOppMenu
                    // @ts-expect-error TS(2322) FIXME: Type 'SupporticleInterface[] | undefined' is not a... Remove this comment to see the full error message
                    menuData={{ 'All Articles': supporticlesData }}
                    panel={true}
                    setDisplayedArticle={(event) => handleScrollToArticle(event)}
                    theme={theme}
                  />
                )}
              </Stack>
              {panelArticles?.map((article: SupporticleInterface, index: number) => (
                <Supporticle article={article} user={user} theme={theme} panel key={index.toString()} />
              ))}
              {supporticlesData && !panelArticles?.length && (
                <Typography p={2} textAlign='center' variant='h6'>
                  No campaign articles exist for this campaign
                </Typography>
              )}
            </>
          ) : (
            <>
              <Stack style={{ width: '100%' }} alignItems='center'>
                {!isClientPortal && (
                  <Button
                    variant='contained'
                    color='secondary'
                    style={{ height: '40px' }}
                    onClick={() => setClientPreviewOpen(false)}
                  >
                    Close Client Portal Preview
                  </Button>
                )}

                {panelArticles?.length > 1 && (
                  <CaseOppMenu
                    // @ts-expect-error TS(2322) FIXME: Type 'SupporticleInterface[] | undefined' is not a... Remove this comment to see the full error message
                    menuData={{ 'All Articles': supporticlesData }}
                    panel={true}
                    setDisplayedArticle={(event) => handleScrollToArticle(event)}
                    theme={theme}
                  />
                )}

                {panelArticles?.map((article: SupporticleInterface, index: number) => (
                  <Supporticle
                    article={article}
                    user={user}
                    theme={theme}
                    panel
                    token={token}
                    key={index.toString()}
                    isClientPortal
                  />
                ))}
              </Stack>
            </>
          )}
        </>
      ) : (
        <SkeletonText height={'100%'} text='Articles Loading' style={{ margin: 0 }} />
      )}
    </Stack>
  )
}

export default memo(SupportPanel)
