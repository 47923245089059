/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'reactn'
import Loading, { LoadingAnimation } from './Loading'
import Header from './Header'
import PropTypes from 'prop-types'
import { hasAccess } from '../../src/helpers/helper'
import Unauthorized from '../Common/Unauthorized'
import { SnackbarProvider } from 'notistack'
import Welcome from '../Common/Welcome'
import { clientWindow } from '../../src/helpers/helper'
import { useSession } from 'next-auth/react'

const thisFile = 'App ' // eslint-disable-line no-unused-vars

const App = (props) => {
  // Extract expected props
  const {
    noMargin,
    children,
    user,
    showMenu = true,
    accessLocation = '',
    snackProvider = false,
    maxSnacks = 3,
    headerBoard,
    mainStyle = { marginTop: !noMargin ? '4em' : 'unset' },
    hideHeader = false,
    router
  } = props
  const [needsLogin, setNeedsLogin] = useState(false)
  const { data: session } = useSession({
    required: true,
    onUnauthenticated: () => {
      if (accessLocation === 'false') return
      setNeedsLogin(true)
    }
  })

  // Add Chatlio Slack chat widget snippet if it's a firm user
  useEffect(() => {
    // this is an exception to the the accessRules length check since it adds a dom element that is injected from outside our code
    if (user && hasAccess('component:chatlioWidget', user)) {
      const script = document.createElement('script')
      script.src = '/chatlioWidget.js'
      script.async = true
      document.body.appendChild(script)
      return () => {
        document.body.removeChild(script)
      }
    }
  }, [user])

  if (user === undefined) {
    // User is still being checked if undefined. Will be null or set once checked.
    return (
      <div data-testid='qa-app-loading'>
        <LoadingAnimation
          {...{
            message: 'Authenticating User',
            user
          }}
        />
      </div>
    )
  } else {
    // user is done being checked, will be either null for not logged in or will be set to logged in user
    let content
    if (user) {
      // logged in user and permissions to be checked
      if (
        accessLocation &&
        (hasAccess(accessLocation, user) ||
          (accessLocation === 'page:partners' && user?.permissions.includes('read:accessAllPartnerPortals')))
      ) {
        // check if user has permissions
        content = children
      } else if (accessLocation && !hasAccess(accessLocation, user)) {
        // if the user didn't have ALL of the permissions in the accessRules[location], then content will be unset
        if (!content)
          content = (
            <div>
              {' '}
              <Unauthorized />{' '}
            </div>
          ) // User is Unauthorized to access this page
      } else {
        content = children
      }
      if (clientWindow?.DD_RUM) {
        // Set DD_RUM user
        clientWindow.DD_RUM.setUser({
          id: user?.user_id,
          name: user?.name,
          email: user?.email,
          userPermissions: user?.permissions?.join(', ')
        })
        clientWindow.DD_RUM.startSessionReplayRecording()
      }
    } else if (user === null && accessLocation === 'false') {
      // logged in or guest user and non gate page, give it to em
      // ************* NO GATE ************
      content = children
    } else if (!session && !needsLogin) {
      content = (
        <div data-testid='qa-app-loading'>
          <LoadingAnimation
            {...{
              message: 'Authorizing User',
              user
            }}
          />
        </div>
      )
    } else {
      // No user here could be from wrong app version.  Welcome component will check local storage
      // For a re-direct
      needsLogin && (content = <Welcome />) // else show User Login/Welcome screen
    }
    if (snackProvider) {
      content = (
        <SnackbarProvider maxSnack={maxSnacks} preventDuplicate dense>
          {content}
        </SnackbarProvider>
      )
    }
    return (
      <div style={{ height: '100%' }}>
        <Loading />
        {!hideHeader && (
          <Header
            user={user}
            showMenu={showMenu}
            headerBoard={headerBoard}
            router={router}
            accessLocation={accessLocation}
          />
        )}
        <main style={mainStyle}>{content}</main>
      </div>
    )
  }
}

App.propTypes = {
  theme: PropTypes.object,
  children: PropTypes.any,
  user: PropTypes.object,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  accessLocation: PropTypes.string,
  headerBoard: PropTypes.any,
  hideHeader: PropTypes.bool,
  snackProvider: PropTypes.bool,
  router: PropTypes.object,
  mainStyle: PropTypes.object,
  noMargin: PropTypes.bool,
  showMenu: PropTypes.bool
}
export default App
