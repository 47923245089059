import React, { useState } from 'reactn'
import classNames from 'classnames'
// MUI Core
import IconButton from '@mui/material/IconButton'
import Drawer from '@mui/material/Drawer'
import Tooltip from '@mui/material/Tooltip'

import { ChevronRight } from '@mui/icons-material'
import HelpIcon from '@mui/icons-material/Help'
import SupportPanel from '../Opportunities/oppFSD/speedDials/SupportPanel'
import supportStyles from './styles/support.module.css'

const Support = (props) => {
  const { router, user, campaignId } = props
  const [supportDrawerOpen, setSupportDrawerOpen] = useState(false)
  const toggleDrawerOpen = () => {
    setSupportDrawerOpen(!supportDrawerOpen)
  }

  const handleClick = () => {
    router.push('/support')
  }

  return (
    <div>
      <IconButton
        color='inherit'
        aria-label='Open drawer'
        onClick={handleClick}
        className={classNames(supportStyles.menuButton, 'qa-button-header-support', {
          [supportStyles.hide]: supportDrawerOpen
        })}
        size='large'
      >
        <Tooltip title='Support' placement='bottom'>
          <HelpIcon width='64px' height='64px' />
        </Tooltip>
      </IconButton>

      <Drawer variant='temporary' open={supportDrawerOpen} anchor='right' style={{ zIndex: 3000 }}>
        <div className={supportStyles.toolbar} style={{ height: '100%' }}>
          <IconButton className='qa-support-drawer-close' onClick={toggleDrawerOpen} size='large'>
            <ChevronRight />
          </IconButton>
          <SupportPanel user={user} campaignId={campaignId} />
        </div>
      </Drawer>
    </div>
  )
}

export default Support
