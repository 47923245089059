import { Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import { signIn } from 'next-auth/react'

const thisFile = 'Welcome ' // eslint-disable-line no-unused-vars

export default function Welcome({ host }) {
  // If we had trouble with app version, this will be set

  const handleLogin = () => {
    signIn('auth0')
  }

  return (
    <div data-testid='welcome' style={{ marginTop: '4em' }}>
      <Typography
        className='qa-login-welcome'
        variant='h4'
        style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}
      >
        Welcome to CaseOpp!
      </Typography>
      <br />
      <Typography
        className='qa-login'
        variant='h5'
        style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}
      >
        Please
        <br />
        <Button
          data-testid='mainLogin'
          onClick={handleLogin}
          style={{
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
        >
          login
        </Button>
      </Typography>
      <br />
      <Typography
        className='qa-prohibited'
        variant='h6'
        style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}
      >
        Unauthorized access prohibited.
      </Typography>
    </div>
  )
}
