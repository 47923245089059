/* eslint-disable no-unused-vars */
import {
  consoleError,
  baseContext,
  deepCopy,
  safeAwait,
  getJsonWithErrorHandling,
  jsonPar,
  getHtmlTagContentById,
  consoleInfo
} from '../../src/helpers/helper'
import { apiVercelFallback } from '../../src/helpers/clientSide/fetch'
const thisFile = 'src/helpers/userHelper.js '
/* eslint-enable no-unused-vars */
export const auth0ManagementClientConfig = {
  domain: process.env.AUTH0_MANAGEMENT_DOMAIN,
  clientId: process.env.AUTH0_MANAGEMENT_CLIENT_ID,
  clientSecret: process.env.AUTH0_MANAGEMENT_CLIENT_SECRET,
  scope: 'read:users update:users read:roles create:users'
}

/**
 * Calls the getUsers endpoint and sets state if provided.
 * @param {Function} setUsersCallback
 * @param {Function} setUsersLoadingCallback
 */
export const getAllUsers = async (setUsersCallback, setUsersLoadingCallback = false) => {
  const [errors, getUsersResult] = await safeAwait(getUsers())
  if (!errors && getUsersResult && setUsersCallback) setUsersCallback(getUsersResult)
  if (setUsersLoadingCallback) setUsersLoadingCallback(false)
}

/**
 * calls getUsers endpoint, returns only userGroups
 * @returns {Promise<Array>} userGroups
 */
export const getUserGroups = async () => {
  const [errors, getUsersResult] = await safeAwait(getUsers())
  if (!errors && getUsersResult) {
    const allGroups = []
    for (const user of getUsersResult) {
      if (user?.app_metadata?.groups?.length) allGroups.push(user.app_metadata.groups)
    }
    return [...new Set(allGroups.flat(2))]
  } else if (errors) {
    consoleError(thisFile, 'getUserGroups ERROR: ', errors)
    return []
  }
}

/**
 * Client Side helper that fetches all users via the api/getUsers endpoint
 * @returns {Array} users
 */
export const getUsers = async () => {
  try {
    const usersFetch = await apiVercelFallback('/getUsers', {
      // eslint-disable-line no-undef
      method: 'GET'
    })
    let errorDetails
    const parsedBody = getJsonWithErrorHandling(await JSON.stringify(usersFetch), { throwError: false })
    if (parsedBody.error && parsedBody.text?.substr && parsedBody.text.substr(0, 15) === '<!DOCTYPE html>') {
      // Next Error
      const nextData = jsonPar(getHtmlTagContentById(parsedBody.text, 'script', '__NEXT_DATA__'))
      consoleInfo(thisFile, 'createOppAPI nextData:', nextData)
      errorDetails = nextData?.err?.stack || parsedBody.text
      consoleError(thisFile, 'errorDetails:', errorDetails)
      throw new Error(parsedBody.error)
    } else if (parsedBody.error) {
      consoleError(thisFile, 'parsedBody.error:', parsedBody)
      throw new Error(parsedBody.error)
    } else {
      const formattedUsers = []
      for (const user of parsedBody) {
        user.permissions = user.directlyAssignedPermissions
          ? [...user.permissions, ...user.directlyAssignedPermissions]
          : user.permissions
        formattedUsers.push(user)
      }
      return formattedUsers
    }
  } catch (error) {
    consoleError(thisFile, 'getUsers Error:', error.message)
  }
  return []
}

/**
 *takes users and organizes them alphabetically and by which ones are 'active' or 'inactive' based on blocked value
 * @param {Array} usersData
 * @param {} param.hideBlocked
 * @param {} param.hideOtherUsers
 * @param {Array} includedUsers - array of user ids to include
 * @returns Array of users
 */
export const sortUsers = (
  usersData,
  { hideBlocked = false } = {},
  { hideOtherUsers = false } = {},
  includedUsers = [],
  userByGroup
) => {
  let usersToBeSorted = deepCopy(usersData)
  if (baseContext === 'pro') {
    usersToBeSorted = usersData?.filter((aUser) => {
      const isTestUser = aUser.email.includes('@test')
      return !isTestUser
    })
  }

  const agentsByRole = [
    { name: 'Active Agent', users: [] },
    { name: 'Inactive Agent', users: [] }
  ]
  if (!hideOtherUsers) {
    agentsByRole.push({ name: 'Other', users: [] })
  }
  const agentRoles = ['intakeAgent', 'caseManager', 'remoteIntake']
  const agentUsers = []
  const inactiveAgentUsers = []
  const otherUsers = []

  usersToBeSorted?.sort((a, b) => (a.name > b.name ? 1 : -1)) // sort intakeAgents alphabetically by name
  usersToBeSorted?.forEach((user) => {
    const userIsAgent = user?.roles?.some((role) => agentRoles.includes(role.name))
    const userIsBlocked = user?.blocked
    if (userIsAgent && !userIsBlocked) {
      // Active Agents
      agentUsers.push(user)
    } else if (userIsAgent && userIsBlocked) {
      // Inactive Agents
      inactiveAgentUsers.push(user)
    } else {
      // Other Users
      otherUsers.push(user)
    }
  })
  if (userByGroup !== undefined) {
    usersToBeSorted?.forEach((agent) => {
      if (agent?.roles?.[0]?.name && agent?.roles?.[0]?.name === userByGroup) {
        if (!agent.blocked) {
          agentsByRole[0]?.users.push(agent)
        } else {
          agentsByRole[1]?.users.push(agent)
        }
      }
    })
  } else {
    usersToBeSorted?.forEach((agent) => {
      if (agent?.roles?.[0]?.name) {
        const roleIndex = agentRoles?.findIndex((role) => role === agent.roles[0].name)
        if (roleIndex !== -1) {
          if (!agent.blocked) {
            agentsByRole[0]?.users.push(agent)
          } else {
            agentsByRole[1]?.users.push(agent)
          }
        }
      }
    })
  }
  for (const otherUser of otherUsers) {
    agentsByRole[2]?.users.push(otherUser)
  }
  const usersThatAreSorted = []
  agentsByRole?.forEach((userType) => {
    !hideBlocked && usersThatAreSorted.push({ name: userType.name, isLabel: true })
    for (const [, value] of Object.entries(userType.users)) {
      if (!hideBlocked && !hideOtherUsers) {
        usersThatAreSorted.push(value)
      } else if (!hideBlocked && hideOtherUsers) {
        if (userType?.name === 'Others') continue
        usersThatAreSorted.push(value)
      } else if (userType?.name === 'Active Agent') {
        usersThatAreSorted.push(value)
      } else if (includedUsers.includes(value.user_id)) {
        usersThatAreSorted.push(value)
      }
    }
  })
  let includedUsersInSortedUsers = true
  // Check if any of the users in includedUsers are not in usersThatAreSorted
  for (const includedUser of includedUsers) {
    const userIsInSortedUsers = usersThatAreSorted?.some((user) => user.user_id === includedUser)
    if (!userIsInSortedUsers) {
      const user = usersData?.find((user) => user.user_id === includedUser)
      if (includedUsersInSortedUsers) usersThatAreSorted.push({ name: 'Opp Selected Users', isLabel: true })
      usersThatAreSorted.push(user)
      includedUsersInSortedUsers = false
    }
  }
  return usersThatAreSorted
}

module.exports = {
  auth0ManagementClientConfig,
  getUsers,
  getAllUsers,
  getUserGroups,
  sortUsers
}
