// MUI Core
import Typography from '@mui/material/Typography'

const thisFile = 'Unauthorized' // eslint-disable-line no-unused-vars

export default function Unauthorized() {
  return (
    <div data-testid='unauthorized' style={{ marginTop: '1em' }}>
      <Typography variant='h4' style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', color: 'red' }}>
        You are NOT Authorized to Access this Page!
      </Typography>
      <br />
      <Typography variant='h6' style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
        This activity will be reported
      </Typography>
    </div>
  )
}
