// MUI Core
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import InputBase from '@mui/material/InputBase'

import SearchIcon from '@mui/icons-material/Search'
import React, { useState } from 'react'
import { clientWindow } from '../../src/helpers/helper'
import globalSearchStyles from './styles/globalSearch.module.css'
export default function GlobalSearch() {
  const [globalSearchParam, setGlobalSearchParam] = useState('')

  return (
    <Paper className={globalSearchStyles.searchPaper}>
      <InputBase
        className={globalSearchStyles.input}
        placeholder='Search'
        inputProps={{ 'aria-label': 'search global' }}
        onChange={(e) => {
          setGlobalSearchParam(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && clientWindow) {
            clientWindow.location.href = '/globalSearchResults?globalSearch=' + encodeURIComponent(globalSearchParam)
          }
        }}
      />
      <IconButton
        className={globalSearchStyles.iconButton}
        aria-label='search'
        onClick={() => {
          if (clientWindow) {
            clientWindow.location.href = '/globalSearchResults?globalSearch=' + encodeURIComponent(globalSearchParam)
          }
        }}
        size='large'
      >
        <SearchIcon />
      </IconButton>
    </Paper>
  )
}
